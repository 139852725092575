<template>
  <el-drawer title="一番赏详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">乐芒扭蛋详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="pl-40 flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 上传图片 -->
          <template #uploadFile="data">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
          </template>
          <!-- 平台自定义插槽 -->
          <template #goodDetailsList>
            <div class="pos-s t-0 bgc-fff zi-10">
              <div class="pb-20 flex flex-ac flex-jb">
                <div class="fs-14 fw-bold flex-1">
                  <span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
                  <span>系列商品</span>
                </div>
                <div class="flex flex-ac">
                  <div class="mr-30">数量：{{formOpts.formData.goodDetailsList.length}}</div>
                  <el-button type="primary" @click="addDomain">新增</el-button>
                </div>
              </div>
            </div>
            <div v-for="(domains,index) in formOpts.formData.goodDetailsList" class="flex flex-ac mb-20" :key="index">
              <el-checkbox v-model="domains.turnType" :true-label="2" :false-label="1">是否保底</el-checkbox>
              <!-- <el-form-item label="商品种类" label-position="right" :prop="'goodDetailsList.' + index + '.turnType'"
                :rules="{required: true, message: '请至少选择一个商品类型', trigger: 'blur'}" class="flex flex-ac">
                <el-select v-model="domains.turnType" clearable placeholder="请选择">
                  <el-option v-for="item in turnTypes" :key="item.value" :label="item.key"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="商品类型" label-position="right" :prop="'goodDetailsList.' + index + '.turnPropType'"
                :rules="{required: true, message: '请至少选择一个商品类型', trigger: 'blur'}" class="flex flex-ac">
                <el-select v-model="domains.turnPropType" clearable placeholder="请选择">
                  <el-option v-for="item in turnPropTypes" :key="item.value" :label="item.key" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="'商品名称'" label-position="right" :prop="'goodDetailsList.' + index + '.propName'"
                :rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.propName" type="text" placeholder="请输入" clearable
                  v-if="domains.turnPropType == 0"></el-input>
                <el-select v-model="domains.seriesId" filterable clearable placeholder="请选择" @change="handleChange"
                  v-else>
                  <el-option v-for="item in drawsList" :key="item.id" :label="item.seriesName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="参考价格" label-position="right" :prop="'goodDetailsList.' + index + '.propPrice'"
                :rules="{required: true, message: '参考价格不能为空', trigger: 'blur'}" class="flex flex-ac">
                <el-input v-model="domains.propPrice" type="number" clearable></el-input>
              </el-form-item>
              <el-form-item label="参考等级" label-position="right" :prop="'goodDetailsList.' + index + '.propLevel'"
                :rules="{required: true, message: '请至少选择一个参考等级', trigger: 'change'}" class="flex flex-ac">
                <el-input v-model="domains.propLevel" type="number" clearable></el-input>
              </el-form-item>
              <el-form-item label="累积消费" label-position="right" :prop="'goodDetailsList.' + index + '.actyLevelId'"
                :rules="{required: true, message: '请选择累计消费', trigger: 'change'}" class="flex flex-ac">
                <el-select v-model="domains.actyLevelId" placeholder="请选择">
                  <el-option v-for="item in actyLevelList" :key="item.id" :label="item.levelName" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品图片" label-position="right" :prop="'goodDetailsList.' + index + '.propImg'"
                :rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
                <upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
                  iconName="el-icon-upload" iconSize="16" v-model="domains.propImg"></upload-file>
              </el-form-item>
              <el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
                v-if="formOpts.formData.goodDetailsList.length > 1">删除
              </el-button>
            </div>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'rewardDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      goodId: '',//详情id
      isDrawer: false, //详情弹框
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          id: '',  //系列id
          turnName: '',	//系列名称
          shelves: '',//是否上下架
          totalPrice: '',	//售出价格
          money: '10',//回收优惠金余额
          dateDay: '365',//有效期
          luckyNum: '',//	连抽次数
          sort: '',//排序
          turnImg: '',	//封面图
          note: '',  //玩法说明
          goodDetailsList: [{
            turnType: '1',//0指定 1随机 2保底
            turnPropType: '', //0商品 1系列
            seriesId: '',//系列id
            propName: '', //商品名称
            propPrice: '', //参考价格(前端显示)
            actyLevelId: '', //累积消费
            propLevel: 1, //等级 1-N
            propImg: '', //商品图片
          }], //商品信息
        },
        fieldList: [
          { label: '扭蛋活动名称', value: 'turnName', comp: 'el-input' },
          { label: '售出价格', value: 'totalPrice', type: 'number', comp: 'el-input' },
          { label: '上下架售罄', value: 'shelves', type: 'select-arr', comp: 'el-select', list: 'shelves', arrLabel: 'key', arrKey: 'value' },
          { label: '连抽次数', value: 'luckyNum', type: 'number', comp: 'el-input' },
          { label: '回收优惠金', value: 'money', type: 'number', comp: 'el-input' },
          { label: '有效期', value: 'dateDay', type: 'number', comp: 'el-input' },
          { label: '排序', value: 'sort', type: 'number', comp: 'el-input' },
          { label: '玩法说明', value: 'note', type: 'textarea', comp: 'el-input', className: 't-form-block' },
          { label: '封面图', value: 'turnImg', slotName: 'uploadFile' },
          { label: '', slotName: 'goodDetailsList', className: 't-form-block' },
        ],
        rules: {
          turnName: [{ required: true, message: '请输入系列名称', trigger: 'blur' }],
          shelves: [{ required: true, message: '请至少选择一个状态', trigger: 'change' }],
          totalPrice: [{ required: true, message: '请输入现价', trigger: 'blur' }],
          money: [{ required: true, message: '请输入回收优惠金额度', trigger: 'blur' }],
          dateDay: [{ required: true, message: '请输入优惠金有效天数', trigger: 'blur' }],
          luckyNum: [{ required: true, message: '请输入连抽次数', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {
          shelves: this.$store.state.menu.dictList.shelves
        }
      },
      // 类型
      turnTypes: [{ value: 0, key: '指定' }, { value: 1, key: '随机' }, { value: 2, key: '保底', }],
      turnPropTypes: [{ value: 0, key: '商品' }, { value: 1, key: '系列' }],
      drawsList: [],
      actyLevelList: [],
    }
  },
  mounted () { },
  methods: {
    // 初始化
    init (id) {
      var that = this
      that.isDrawer = true
      that.goodId = id
      this.findBoxGoods()
      this.findActyLevelConsumeList()
      if (id) {
        that.getDetails(id)
      }
    },
    //抽盒商品
    findBoxGoods () {
      this.$http.get('/boxSeries/findBoxSeries', {
        params: {
          currentPage: 1,
          pageSize: 2000,
        }
      }).then(({ data: result }) => {
        this.drawsList = result.data.list
      }).catch(err => { })
    },
    //获取活动累计消费
    findActyLevelConsumeList () {
      this.$http.get('/actyLevelConsume/findActyLevelConsumeList', {
        params: {
          currentPage: 1,
          pageSize: 20,
          actyType: 0,
        }
      }).then(({ data: result }) => {
        this.actyLevelList = result.data.list
      })
    },
    handleChange (val) {
      let drawsList = this.drawsList
      let list = this.formOpts.formData.goodDetailsList
      let forma = list.find(ele => { return ele.seriesId == val })
      let arr = drawsList.find(ele => { return ele.id == val })
      forma.propName = arr.seriesName
      forma.propPrice = arr.soldPrice
      forma.propImg = arr.coverImg
      console.log(forma, arr)
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      // findTurnById({
      this.$http.post('turn/findTurnById', {
        turnId: id
      }).then(({ data: res }) => {
        let goodData = res.data.turns
        goodData.goodDetailsList = res.data.props
        this.formOpts.formData = goodData
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
      }
    },
    // 新增商品
    addDomain () {
      let goodList = this.formOpts.formData.goodDetailsList
      let propLevel = 1
      if (goodList.length > 0) {
        propLevel = goodList[goodList.length - 1].propLevel + 1
      }
      this.formOpts.formData.goodDetailsList.push({
        turnType: '1',//0指定 1随机 2保底
        turnPropType: '', //0商品 1系列
        seriesId: '',//系列id
        propName: '', //商品名称
        propPrice: '', //参考价格(前端显示)
        actyLevelId: '', //累积消费
        propLevel: propLevel, //等级 1-N
        propImg: '', //商品图片
      })
    },
    removeDomain (index) {
      this.formOpts.formData.goodDetailsList.splice(index, 1)
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          if (!valid) return
          let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
          goodData.json = JSON.stringify(goodData.goodDetailsList)
          delete goodData.goodDetailsList
          if (goodData.turnPropType == 0) {
            delete goodData.seriesId
          }
          if (goodData.id) {    //修改
            // editTurn(goodData).then(res => {
            this.$http.post('turn/editTurn', goodData).then(res => {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
              this.butLoading = false
              this.$emit('refresh')
              this.handleClose()
            }).catch(err => {
              this.butLoading = false
            })
          } else {
            // addTurn(goodData).then(res => {
            this.$http.post('turn/addTurn', goodData).then(res => {
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.butLoading = false
              this.$emit('refresh')
              this.handleClose()
            }).catch(err => {
              this.butLoading = false
            })
          }
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>